import { startLoading, stopLoading } from './PageAction';
import { formAPI } from '../../api/form/FormAPI';
import { setFormActionHistories, updateFormActionHistory } from './FormActionReducer';

export const SET_FORM_LIST_FETCHING = 'SET_FORM_LIST_FETCHING';
function setFormListFetching(fetching) {
    return {
        type: SET_FORM_LIST_FETCHING,
        fetching,
    };
}
export const SET_FORM_LIST_SUCCESS = 'SET_FORM_LIST_SUCCESS';
function setFormListFetchSuccess() {
    return {
        type: SET_FORM_LIST_SUCCESS,
        lastModified: new Date().getTime(),
    };
}
export const SET_FORM_LIST_ERROR = 'SET_FORM_LIST_ERROR';
function setFormListFetchError(error) {
    return {
        type: SET_FORM_LIST_ERROR,
        error,
    };
}
export const SET_FORM_LIST = 'SET_FORM_LIST';
function getFormList(templateId, formData) {
    const formList = formData.forms;
    return {
        type: SET_FORM_LIST,
        payload: {
            id: templateId,
            data: {
                formActionConfigurations: formData.form_action_configurations || [],
                headers: formData.headers || {},
                forms: formList || [],
                statuses: formData.forms.map(f => f.form_status) || [],
                lastModified: new Date().getTime(),
            },
        },
    };
}
export const SET_TEMPLATE_FORM = 'SET_TEMPLATE_FORM';
function setTemplateFormById({ formId, templateId, form }) {
    return {
        type: SET_TEMPLATE_FORM,
        payload: {
            id: formId,
            templateId,
            form,
        },
    };
}

function startFetching(dispatch) {
    dispatch(startLoading());
    dispatch(setFormListFetching(true));
}
function stopFetching(dispatch) {
    dispatch(setFormListFetching(false));
    dispatch(stopLoading());
}

export function actionGetFormList(templateId, checkedStatuses, checkedAssignees, createdFromDate, createdToDate, updatedFromDate, updatedToDate, applicationId, uen, companyName) {
    return async dispatch => {
        startFetching(dispatch);

        try {
            const { headers, forms, form_action_histories, form_action_configurations } =
                await formAPI.getFormList(templateId, checkedStatuses, checkedAssignees, createdFromDate, createdToDate, updatedFromDate, updatedToDate, applicationId, uen, companyName);
            const formsWithHeaders = {
                headers,
                forms,
                form_action_configurations,
            };
            dispatch(getFormList(templateId, formsWithHeaders));
            dispatch(setFormListFetchSuccess());
            dispatch(setFormActionHistories(form_action_histories));
        } catch (e) {
            dispatch(setFormListFetchError(e.responseJSON?.status_desc || e));
        } finally {
            stopFetching(dispatch);
        }
    };
}

export function actionUpdateTemplateForm({ formId, templateId, uid, data }) {
    return async dispatch => {
        startFetching(dispatch);
        try {
            const { form, form_action_history: formActionHistory } = await formAPI.callFormAction({
                formId,
                uid,
                data,
            });
            dispatch(setTemplateFormById({ formId, templateId, form }));
            if (formActionHistory) {
                dispatch(updateFormActionHistory(formActionHistory));
            }
        } catch (e) {
            throw e;
        } finally {
            stopFetching(dispatch);
        }
    };
}
